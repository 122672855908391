<script setup>
import { WdsButtonLink, WdsCampaignBanner } from '@wds/components';

const props = defineProps({
  source: {
    type: String,
    default: () => '',
  },
});
const { t } = useI18n();

const testingStore = useTestingStore();
const { $tracking } = useNuxtApp();
const sdData = testingStore.getFeatureVariables('web_seasonalDeals');

const sdProperties = computed(() => {
  return sdData?.featured_props?.properties || {};
});
const isCarouselEnabled = computed(() => {
  return sdData?.homepage?.showCarousel;
});

// METHODS
function getUrlToSeasonalDeals(termsAndConditionsKey) {
  const termsAndConditionsUrl = termsAndConditionsKey === true ? '#tcs' : '';
  return `https://global.hostelworld.com/global-travel-deals${termsAndConditionsUrl}`;
}

// ### TRACKING
const trackBannerClick = function () {
  $tracking.onCampaignBannerClick(props.source);
};
function handleTCClick() {
  $tracking.onBFTermsConditionsClick();
}
function handleAllDealsClick() {
  $tracking.onSeasonalAllDealsClick();
}
</script>

<template>
  <WdsCampaignBanner
    v-if="!isCarouselEnabled"
    bg-image="https://a.hwstatic.com/image/upload/v1731408608/campaign/backgrounds/small/global-deals-bg.png"
    button-text="Discover all deals"
    link="https://global.hostelworld.com/global-travel-deals"
    :subtitle="$t('t_SDDEALSSUBTITLE')"
    subtitle-color="white"
    tc-link="https://global.hostelworld.com/global-travel-deals#tcs"
    :tc-text="$t('t_TCAPPLY')"
    :title="$t('t_SDDEALSTITLE')"
    title-tag-color="pink"
    class="sd-banner"
    @tc-click="handleTCClick"
    @banner-click="trackBannerClick"
    @button-click="handleAllDealsClick"
  />

  <template v-if="sdProperties?.length && isCarouselEnabled">
    <div class="sd-carousel">
      <h2>{{ $t('t_SDDEALSTITLE') }}</h2>
      <div class="inner-wrapper">
        <p class="text">
          {{ $t('t_SDDEALSSUBTITLE') }}
          <a
            :href="getUrlToSeasonalDeals(true)"
            class="tclink"
            rel="noopener"
            @click="handleTCClick"
          >{{ $t('t_TCAPPLY') }}</a>
        </p>

        <PromotionsHwPopularDealsCards
          :properties="sdProperties"
          source="homepage"
          campaign-type="seasonalDeals"
        />

        <WdsButtonLink
          :href="getUrlToSeasonalDeals(false)"
          :text="t('t_DISCOVERALLDEALS')"
          :title="t('t_DISCOVERALLDEALS')"
          :arial-label="t('t_DISCOVERALLDEALS')"
          rel="noopener"
          icon-end="chevron-right"
          class="sd-view-all"
          @click="handleAllDealsClick"
        />
      </div>
    </div>
  </template>
</template>

<style lang="scss" scoped>
.sd-banner {
  margin-bottom: $wds-spacing-m;
}

.sd-carousel {
  margin: 0 auto $wds-spacing-m;
  background-image: url('https://a.hwstatic.com/image/upload/v1731408485/campaign/backgrounds/large/global-deals-bg.png');
  background-repeat: no-repeat;
  background-position: left center;
  background-size: cover;
  min-height: wds-rem(510px);
  border-radius: $wds-spacing-m;
  padding-top: $wds-spacing-l;
  line-height: $wds-line-height-body-2;

  @include tablet {
    background-image: url('https://a.hwstatic.com/image/upload/v1731408485/campaign/backgrounds/large/global-deals-bg.png');
  }

  .inner-wrapper {
    padding: $wds-spacing-m;
    display: flex;
    flex-direction: column;
  }

  h2 {
    @include title-2-bld;

    background-color: $wds-color-pink;
    color: $wds-color-white;
    word-wrap: break-word;
    padding: $wds-spacing-xs $wds-spacing-s;
    clip-path: polygon(0 0, 100% 0, calc(100% - 4px) 100%, 0 100%);
    text-align: left;
    margin-right: $wds-spacing-m;

    @include tablet {
      @include title-1-bld;

      width: fit-content;
      padding: $wds-spacing-xs $wds-spacing-xl;
    }
  }

  .text {
    @include body-2-reg;

    color: $wds-color-white;
    z-index: 1;
    display: inline;
    width: fit-content;
    font-weight: $wds-font-weight-bold;
  }

  .tclink {
    color: $wds-color-white;
    text-decoration: underline;
    cursor: pointer;
    padding: $wds-spacing-s 0;
    white-space: nowrap;

    &:focus-within,
    &:focus,
    &:focus-visible,
    &:active {
      border-radius: $wds-spacing-s;
      box-shadow:
        wds-rem(1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(-1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(1px) 0 0 wds-rem(2px) $wds-color-ink-darker,
        wds-rem(-1px) 0 0 wds-rem(2px) $wds-color-ink-darker;
    }
  }

  .sd-view-all {
    background: $wds-color-white;
    color: $wds-color-ink-darker;
    width: 100%;
    padding: 0 $wds-spacing-m;
    cursor: pointer;
    align-self: flex-end;
    text-decoration: none;

    &:focus-within {
      box-shadow:
        wds-rem(1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(-1px) 0 0 wds-rem(1px) $wds-color-white,
        wds-rem(1px) 0 0 wds-rem(2px) $wds-color-ink-darker,
        wds-rem(-1px) 0 0 wds-rem(2px) $wds-color-ink-darker;
    }

    @include tablet {
      width: fit-content;
    }
  }
}
</style>
